import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
Vue.prototype.$http = 'https://admin.asia.didigo.es'
const axios2 = axios.create({
	baseURL: 'https://admin.asia.didigo.es', // api 的 base_url
	timeout: 0 // 请求超时时间
})
Vue.prototype.$axios = axios2
Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
	el: '#app',
	router,
	store,
	template: '<App/>',
	components: { App }
})
